import React, { useEffect, useState } from "react"
import {
  forgotPasswordRequest,
  forgotPasswordReset,
} from "../services/forgot-password"
import Layout from "../components/global/layout"
import Container from "../components/container/container"
import { FormProvider, useForm } from "react-hook-form"
import Input from "../components/form/Input"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { navigate } from "gatsby"
import FormWrapper from "../components/form/form-wrapper"
import SimpleIntro from "../components/global/simple-intro"
import Button from "../components/button/button"
import { toast } from "react-toastify"

const ForgotPasswordResetPage = ({
  component: Component,
  location,
  ...rest
}) => {
  const [email, setEmail] = useState("")

  useEffect(() => {
    if (location.state && location.state.email) {
      // console.log("location.state.email", location.state.email)
      setEmail(location.state.email)
    } //else if email is in url params
    else if (location) {
      const params = new URLSearchParams(location.search)
      const rawEmail = params.get("email")
      if (rawEmail) {
        setEmail(rawEmail)
      } else {
        navigate("/forgot-password-request")
      }
    } else {
      navigate("/forgot-password-request")
    }
  }, [])

  return <ChangePasswordForm email={email} />
}

const ChangePasswordForm = ({ email }) => {
  const [processing, setProcessing] = useState(false)

  const schema = yup
    .object({
      otp: yup.string().required("Code is required"),
      new_password: yup.string().required("Password Name is required"),
      confirm_password: yup
        .string()
        .required("Confirm Password is required")
        .oneOf([yup.ref("new_password"), null], "Passwords must match"),
    })
    .required()

  const methods = useForm({
    resolver: yupResolver(schema),
  })
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = methods

  const [generalMsg, setGeneralMsg] = useState("")

  const onSubmit = async (data) => {
    setProcessing(true)
    data.email = email
    forgotPasswordReset(data).then((res) => {
      if (res.status) {
        navigate(`/login`, {
          state: { generalMsg: "Your Password was reset, please log in" },
        })
      } else {
        setProcessing(false)
        toast.error(res.message)
      }
    })
  }

  return (
    <div>
      {email && (
        <Layout whitePreFooter={true}>
          <Container gutters width={"xsmall"}>
            <SimpleIntro title={"Reset Password"} />
            <FormWrapper
              heading={"Reset Password"}
              text={
                "Please enter the code we sent to your email and set your new password."
              }
            >
              <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)} noValidate>
                  <Input
                    label={"Code from email"}
                    name="otp"
                    id="otp"
                    {...register("otp")}
                    required
                    placeholder={"Enter the OTP"}
                    errorMessage={errors.otp?.message}
                  />
                  <Input
                    label={"New Password"}
                    name="new_password"
                    type="password"
                    {...register("new_password")}
                    required
                    placeholder={"New Password"}
                    errorMessage={errors.new_password?.message}
                  />
                  <p>
                    Password must be between 6-12 characters including one
                    capital, one small letter and one number.
                  </p>
                  <Input
                    label={"Confirm Password"}
                    name="confirm_password"
                    type="password"
                    {...register("confirm_password")}
                    required
                    placeholder={"Confirm New Password"}
                    errorMessage={errors.confirm_password?.message}
                  />
                  <Container pt={1}>
                    <Button
                      primary
                      disabled={processing}
                      fullWidth
                      type="submit"
                    >
                      {processing ? "Processing..." : "Reset Password"}
                    </Button>
                  </Container>
                </form>
              </FormProvider>
            </FormWrapper>
          </Container>
        </Layout>
      )}
    </div>
  )
}

export default ForgotPasswordResetPage
